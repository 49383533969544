<template>
  <div class="login">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="form" label-width="100px" @keyup.enter.native="handleSubmit">
      <div class="content">
        <div class="title">高支模在线监测预警云平台</div>
        <div class="sub_title"></div>
        <!-- <div class="title">在线安全监测系统</div> -->
        <!-- <div class="sub_title">登录到监管云信息平台</div> -->
        <el-form-item prop="username">
          <span class="el-icon-user input-icon" />
          <el-input placeholder="请输入用户名" type="text" v-model="loginForm.username" />
        </el-form-item>
        <el-form-item prop="password">
          <span class="el-icon-lock input-icon" />
          <el-input placeholder="请输入密码" type="password" v-model="loginForm.password" show-password />
        </el-form-item>
        <!-- 验证码的功能模块 -->
        <el-form-item>
          <el-button class="button" size="medium" type="primary" @click="handleSubmit" :disabled="isFlag">登录</el-button>
        </el-form-item>
      </div>
    </el-form>
    <footer>
      <!--   <span>技术支持单位：广州翰南工程技术有限公司</span> -->
      <a class="link" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">粤ICP备17097487号</a>
      <!-- <a class="link" href="https://beian.miit.gov.cn" target="_blank">粤ICP备17097487号</a> -->
    </footer>
  </div>
</template>

<script>
import { login } from '@/api/User'

// import md5 from 'js-md5'

export default {
  name: 'login',
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value.length < 4) {
        callback(new Error('用户名不得少于4位'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 4) {
        callback(new Error('密码不得少于4位'))
      } else {
        callback()
      }
    }

    return {
      label: {
        username: '用户名: ',
        password: '密' + '\xa0\xa0\xa0' + '码: ',
      },
      loginForm: {
        username: '',
        password: '',
      },
      isFlag: false,
      // 登录规则，移开焦点触发validateUsername
      loginRules: {
        username: [
          {
            required: true,
            trigger: 'blur',
            validator: validateUsername,
          },
        ],
        password: [
          {
            required: true,
            trigger: 'blur',
            validator: validatePassword,
          },
        ],
      },
    }
  },
  // 处理登录事件,md5加密提交表单
  methods: {
    // 处理登录表单提交事件
    handleSubmit() {
      const user = {
        username: this.loginForm.username,
        password: this.loginForm.password,
        // password:  md5(this.loginForm.password),
      }
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          console.log(user)
          this.loginFun(user)
        }
      })
    },

    async loginFun(user) {
      const { data: res } = await login(user)
      if (res.code === 200) {
        console.log('res.data', res.data)
        this.$store.commit('updateUserInfo', res.data)
        console.log(this.$store.state)
        this.$router.push({ name: 'MapChart' })
      } else {
        console.log(res)
      }
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
    },
    _isMobile() {
      console.log(navigator.userAgent)
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
  },

  mounted() {},
  created() {
    if (this._isMobile()) {
      this.isFlag = true
      this.$message({
        message: '抱歉,暂不支持移动端设备访问',
        type: 'warning',
      })
    }
  },
}
</script>

<style scoped lang="scss">
.el-form-item ::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

.el-form-item ::v-deep .el-form-item__error {
  color: red;
}

.login {
  width: 100%;
  height: 100%;
  // background: url("~@/assets/image/loginbg.jpg") no-repeat #406673;
  background-size: 100% 100%;
  background-attachment: fixed;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 400px;
  height: 400px;
  padding: 20px;
  border-radius: 10px;
}

.title {
  text-align: center;
  font-size: 36px;
  color: #202949;
  font-weight: bolder;
  margin-bottom: 20px;
}

.sub_title {
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 60px;
}

.el-form-item {
  position: relative;
}

.input-icon {
  position: absolute;
  z-index: 999;
  left: 12px;
  top: 16px;
  transform: scale(1.4);
}

.input-iconfont-icon {
  position: absolute;
  z-index: 999;
  transform: scale(1.2);
  line-height: 48px;
  left: 11px;
}

.el-input ::v-deep .el-input__inner {
  padding-left: 40px;
  height: 45px;
}

.el-button {
  width: 100%;
  margin-top: 20px;
  height: 50px;
  font-size: 18px;
}

.captcha-img {
  position: absolute;
  width: calc(40% - 20px);
  display: inline-block;
  height: 40px;
  margin: 2.5px 0;
  margin-left: 20px;
  border-radius: 3px;
}

footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  text-align: center;
  font-size: 14px;
  color: #fff;
  height: 32px;
  line-height: 32px;
  background: #406673;
}

.link {
  padding-left: 20px;

  &:link,
  &:visited,
  &:hover,
  &:hover {
    text-decoration: none;
    color: #fff;
  }
}

@media screen and (max-width: 600px) {
  .login {
    width: 100%;
  }

  .form {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  }

  footer {
    display: none;
  }
}
</style>
